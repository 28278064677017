import React, { useEffect, useState } from "react";
import Button from "../buttons/Button";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import View from "../view/View";
import styles from "./styles/MobileMenu.module.css";
import navStyles from "./styles/Nav.module.css";

export default function MobileMenu() {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const lines = document.querySelectorAll(`.${styles.line}`);
    const menu = document.querySelectorAll(`.${styles.mobileMenu}`)[0];
    if (menuOpen) {
      lines.forEach((line) => line.classList.add(styles.animateNavLine));
      menu.classList.add(styles.openMobileNavMenu);
    } else {
      lines.forEach((line) => line.classList.remove(styles.animateNavLine));
      menu.classList.remove(styles.openMobileNavMenu);
    }
  }, [menuOpen]);

  return (
    <View className={styles.mobileButtonContainer}>
      <Button
        onClick={() => setMenuOpen(!menuOpen)}
        className={styles.mobileButton}
      >
        <View className={styles.line}></View>
        <View className={styles.line}></View>
      </Button>
      <View className={styles.mobileMenu}>
        <View className={styles.mobileMenuWrapper}>
          <View className={`${navStyles.logoContainer} ${navStyles.fixedLogo}`}>
            <Button
              className={styles.cleanButton}
              onClick={() => setMenuOpen(false)}
              to="/"
            >
              <img
                className={navStyles.logo}
                src="./assets/images/logo_white.png"
                alt="Warren Logo"
              />
            </Button>
          </View>
          <Button
            onClick={() => setMenuOpen(!menuOpen)}
            className={`${styles.mobileButton} ${styles.fixedMobileButton}`}
          >
            <View className={styles.line}></View>
            <View className={styles.line}></View>
          </Button>
          <View className={styles.mobileNavButtonContainer}>
            <SecondaryButton
              size="small"
              onClick={() => setMenuOpen(false)}
              linkClassName={styles.maxWidthButton}
              className={styles.maxWidthButton}
              to="/signup"
            >
              Join our newsletter
            </SecondaryButton>
            <PrimaryButton
              size="small"
              onClick={() => setMenuOpen(false)}
              bright
              linkClassName={styles.maxWidthButton}
              className={styles.maxWidthButton}
              to="/login"
            >
              Already a member? Sign in
            </PrimaryButton>

          </View>
        </View>
      </View>
    </View>
  );
}
