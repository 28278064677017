import React from "react";
import { useLocation } from "react-router-dom";
import Paragraph from "../text/paragraph/Paragraph";
import styles from "./styles/Footer.module.css";

export default function Footer() {

  const location = useLocation();
  
  if (location.pathname === "/brand" || location.pathname === "/login" || location.pathname === "/signup") {
    return null;
  }
  
  return (
    <footer className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            src="./assets/images/logo.png"
            alt="Warren Logo"
          />
        </div>
        <div className={styles.rightContainer}>
          <Paragraph size="small">© Warren AI 2023</Paragraph>
        </div>
      </div>
    </footer>
  );
}
