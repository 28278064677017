import React from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import TextInput from "../input/TextInput";
import Container from "../navigation/Container";
import H4 from "../text/heading/H4";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import styles from "./styles/Landing.module.css";

export default function Landing() {
  return (
    <Container className={styles.container}>
      <H4>Check back later</H4>
      <Spacer size="small" />
      <Spacer size="medium" />
      <Paragraph className={styles.message}>Join our newsletter and receive all the important financial news to help you trade more intelligently.</Paragraph>
      <Spacer size="small" />
      <Spacer size="medium" />
      <Spacer size="small" />
      <View gap="medium" centerHorizontally className={styles.waitlistContainer}>
        <TextInput size="big" placeholder="Enter your email"/>
        <PrimaryButton size="big">Join newsletter</PrimaryButton>
      </View>
    </Container>
  );
}
