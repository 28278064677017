import { ChakraProvider } from "@chakra-ui/react";
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import "./App.css";
import Footer from "./components/navigation/Footer";
import Nav from "./components/navigation/Nav";
import Landing from "./components/pages/Landing";
import Login from "./components/pages/Login";
import StyleGuide from "./components/pages/StyleGuide";

export default function App() {
  return (
    <ChakraProvider>
      <Router>
        <AppContent />
      </Router>
    </ChakraProvider>
  );
}

function AppContent() {

  return (
    <div className="body">
      <Nav />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route path="/brand" element={<StyleGuide />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Login signup />} />
      </Routes>
      <Footer />
    </div>
  );
}
