import React from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import Link from "../links/Link";
import View from "../view/View";
import styles from "./styles/Nav.module.css";
import MobileMenu from "./MobileMenu";

export default function Nav() {
  return (
    <header className={styles.container}>
      <View className={styles.logoContainer}>
        <Link to="/">
          <img
            className={styles.logo}
            src="./assets/images/logo.png"
            alt="Warren Logo"
          />
        </Link>
      </View>
      <View className={styles.buttonContainer}>
        <View className={styles.desktopButtonContainer}>
          <SecondaryButton to="/login">Sign In</SecondaryButton>
        </View>
        <View className={styles.mobileButtonContainer}>
          <MobileMenu />
        </View>
      </View>
    </header>
  );
}
