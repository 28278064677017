import React from "react";
import Button from "./Button";
import styles from "./styles/SecondaryButton.module.css";

export default function SecondaryButton(props) {
  return (
    <Button
      disabled={props.disabled}
      linkClassName={props.linkClassName}
      to={props.to}
      size={props.size}
      onClick={props.onClick}
      className={`${styles.defaults} ${props.className}`}
    >
      {props.children}
    </Button>
  );
}
