import React, { useState } from "react";
import { Check } from "react-feather";
import Button from "../buttons/Button";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import styles from "./styles/RadioButton.module.css";

export default function RadioButton(props) {

  const [accepted, setAccepted] = useState(false);

  return (
    <Button
      onClick={() => setAccepted(!accepted)}
      className={styles.radioButton}
    >
      <View className={styles.radioButtonContainer}>
        <View
          className={`${styles.checkbox} ${accepted && styles.checkedCheckbox}`}
        >
          <Check size={15} className={styles.checkboxIcon} />
        </View>
        {props.component}
      </View>
    </Button>
  );
}
