import React from "react";
import styles from "./styles/Button.module.css";
import { Link } from "react-router-dom";

export default function Button(props) {
  return (
    <Link className={props.linkClassName} to={props.to}>
      <button
        disabled={props.disabled}
        onClick={props.onClick}
        className={`${styles.defaults} ${props.className} ${
          props.size == "big"
            ? styles.big
            : props.size == "small"
            ? styles.small
            : ""
        }`}
      >
        {props.children}
      </button>
    </Link>
  );
}
