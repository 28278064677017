import React from "react";
import { Inbox } from "react-feather";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import TextInput from "../input/TextInput";
import Link from "../links/Link";
import Container from "../navigation/Container";
import RadioButton from "../radiobutton/RadioButton";
import radioButtonStyles from "../radiobutton/styles/RadioButton.module.css";
import H5 from "../text/heading/H5";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Separator from "../view/impl/Separator";
import styles from "./styles/Login.module.css";

export default function Login(props) {
  return (
    <Container className={styles.container}>
      <View className={styles.loginContainer}>
        <View className={styles.loginWrapper}>
          <H5>{props.signup ? "Join our newsletter" : "Sign in"}</H5>
          <Paragraph className={styles.paragraph}>Privacy friendly, no password needed.</Paragraph>
          <SecondaryButton linkClassName={styles.signInWithGoogleLink} className={styles.signInWithGoogle}>
            <img
              className={styles.googleIcon}
              src="./assets/images/google.png"
              alt="Google Icon"
            />
            {props.signup ? "Sign up with Google" : "Sign in with Google"}
          </SecondaryButton>
          <View className={styles.separatorContainer}>
            <Separator className={styles.separator}></Separator>
            <Paragraph size="small">or</Paragraph>
            <Separator className={styles.separator}></Separator>
          </View>
          <form className={styles.form}>
            <TextInput
              className={styles.input}
              placeholder="Enter your email"
            />
            {props.signup && <RadioButton component={<Paragraph className={radioButtonStyles.radioText}>I have read and agree with the <Link>Privacy Policy</Link> and <Link>Terms of Service</Link></Paragraph>}/>}
            <PrimaryButton disabled={props.signup} className={styles.button}>
              <Inbox size={20} className={styles.magicIcon} /> Send me a magic
              link
            </PrimaryButton>
          </form>
          <Paragraph size="small" className={styles.signUpLinkContainer}>{props.signup ? "Already have an account?" : "Don't have an account?"} <Link to={props.signup ? "/login" : "/signup"}>{props.signup ? "Sign In" : "Sign Up"}</Link></Paragraph>
        </View>
      </View>
    </Container>
  );
}
