import React from "react";
import styles from "./styles/TextInput.module.css";

export default function TextInput(props) {
  return (
    <input
      type="text"
      placeholder={props.placeholder}
      className={`${styles.defaults} ${props.className} ${props.size == 'big' ? styles.big : props.size == 'small' ? styles.small : ''}`}
    />
  );
}
