import React, { useState } from "react";
import Button from "../buttons/Button";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import TextInput from "../input/TextInput";
import Link from "../links/Link";
import Container from "../navigation/Container";
import H1 from "../text/heading/H1";
import H2 from "../text/heading/H2";
import H3 from "../text/heading/H3";
import H4 from "../text/heading/H4";
import H5 from "../text/heading/H5";
import H6 from "../text/heading/H6";
import Subheading from "../text/heading/subheading/Subheading";
import Paragraph from "../text/paragraph/Paragraph";
import Block from "../view/Block";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import styles from "./styles/StyleGuide.module.css";

export default function StyleGuide() {
  
  const [selectedTab, setSelectedTab] = useState("brand story");

  function Content() {
    switch (selectedTab) {
      case "brand story":
        return (
          <View vertical>
            <H2>Brand story</H2>
            <Spacer size="medium" />
            <Spacer size="small" />
            <Block>
              <Paragraph>
                Warren AI was born out of a deep passion for trading and
                technology. The team at Luna AI AB had a vision to create an
                innovative product that would revolutionize the way people trade
                stocks. They wanted to build a platform that would empower
                traders with intelligent insights and enable them to make
                informed decisions.<br></br>
                <br></br>
                The team set out on a journey to create a product that would
                seamlessly integrate cutting-edge technology with a
                user-friendly interface. After years of research and
                development, they finally created Warren AI - a stock trading
                browser and mobile application powered by GPT-4.<br></br>
                <br></br>
                The name Warren was chosen as a tribute to the legendary
                investor, Warren Buffett, whose investment strategies and
                principles inspired the team. The brand's accent color, Deep
                Sea, was chosen to represent the deep insights and knowledge
                that the platform provides to its users.<br></br>
                <br></br>
                With Warren AI, traders can access real-time market data, news,
                and insights, enabling them to make informed decisions about
                their investments. The platform's advanced algorithms analyze
                market trends and patterns, providing users with personalized
                recommendations and insights tailored to their unique investment
                goals.<br></br>
                <br></br>
                Warren AI is more than just a product, it's a community of
                like-minded individuals who are passionate about trading and
                investing. The platform fosters collaboration and encourages
                users to share their knowledge and insights with each other.
                <br></br>
                <br></br>
                At Warren AI, we believe that everyone should have the
                opportunity to succeed in the stock market. Our mission is to
                democratize trading and make it accessible to everyone,
                regardless of their level of experience or financial background.
                <br></br>
                <br></br>
                Join the Warren AI community today and start making smarter
                investment decisions with the power of AI.
              </Paragraph>
            </Block>
          </View>
        );

      case "our mission":
        return (
          <View vertical>
            <H2>Our mission</H2>
            <Spacer size="medium" />
            <Spacer size="small" />
            <Block>
              <Paragraph>
                At Warren AI, our mission is to empower traders with intelligent
                insights and make trading accessible to everyone, regardless of
                their level of experience or financial background. We believe
                that everyone should have the opportunity to succeed in the
                stock market, and our platform is designed to democratize
                trading and level the playing field for all.<br></br>
                <br></br>
                We understand that trading can be complex and intimidating,
                especially for beginners. That's why we are committed to
                providing a user-friendly platform that simplifies the process
                and makes it easy for anyone to get started. Our advanced
                algorithms analyze market trends and patterns, providing users
                with personalized recommendations and insights tailored to their
                unique investment goals.<br></br>
                <br></br>
                We leverage cutting-edge technology, such as GPT-4, to ensure
                that our platform is always at the forefront of innovation. This
                allows us to stay ahead of the curve and provide our users with
                the latest tools and insights to help them make informed
                investment decisions. Whether you're a seasoned trader or a
                beginner, Warren AI has something for everyone.<br></br>
                <br></br>
                At Warren AI, we believe in the power of community. We foster a
                collaborative environment where users can share their knowledge
                and insights with each other, helping each other to learn and
                grow. Our community is made up of traders from all backgrounds,
                from beginners to experts, and we welcome anyone who is
                passionate about trading and investing.<br></br>
                <br></br>
                Our ultimate goal is to help people achieve financial
                independence and success through the power of AI. We believe
                that trading can be a path to financial freedom, and our mission
                is to make that path accessible to everyone.
              </Paragraph>
            </Block>
          </View>
        );

      case "messaging pillars":
        return (
          <View vertical>
            <H2>Messaging pillars</H2>
            <Spacer size="medium" />
            <Spacer size="small" />
            <Block>
              <Paragraph>
                <b>Empowerment</b>: At Warren AI, we're dedicated to empowering
                traders with the resources and insights they need to make
                informed investment decisions. Our platform utilizes
                cutting-edge technology, including GPT-4, to provide
                personalized recommendations tailored to each user's unique
                investment goals.
                <br></br>
                <br></br>
                <b>Accessibility</b>: We believe that trading should be
                accessible to everyone, regardless of their experience or
                financial background. That's why our platform is designed to be
                user-friendly and easy to navigate, making it simple for anyone
                to get started with trading.<br></br>
                <br></br>
                <b>Innovation</b>: We're committed to remaining at the forefront
                of technological innovation and using the latest tools and
                advancements to enhance our users' trading experience. Our use
                of GPT-4 is just one example of how we're leveraging AI to
                provide intelligent insights and recommendations to our users.
                <br></br>
                <br></br>
                <b>Collaboration</b>: We believe that trading is a collaborative
                process, and our community is comprised of like-minded
                individuals who share their knowledge and insights with each
                other. Whether you're a novice or an expert, there is always
                something to learn and share at Warren AI.<br></br>
                <br></br>
                <b>Financial Success</b>: Our ultimate goal is to help our users
                achieve financial independence and success through the power of
                AI. We believe that trading can be a path to financial freedom,
                and we're committed to helping our users reach their goals by
                providing the resources, insights, and support they need to
                succeed.
              </Paragraph>
            </Block>
          </View>
        );

      case "headings":
        return (
          <View vertical>
            <H4>Headings</H4>
            <Spacer size="medium" />
            <Spacer size="small" />
            <Block>
              <Subheading>H1</Subheading>
              <Spacer size="small" />
              <H1>The quick brown fox</H1>
              <Spacer size="medium" />
              <Subheading>H2</Subheading>
              <Spacer size="small" />
              <H2>The quick brown fox</H2>
              <Spacer size="medium" />
              <Subheading>H3</Subheading>
              <Spacer size="small" />
              <H3>The quick brown fox</H3>
              <Spacer size="medium" />
              <Subheading>H4</Subheading>
              <Spacer size="small" />
              <H4>Jumps over the lazy dog</H4>
              <Spacer size="medium" />
              <Subheading>H5</Subheading>
              <Spacer size="small" />
              <H5>Jumps over the lazy dog</H5>
              <Spacer size="medium" />
              <Subheading>H6</Subheading>
              <Spacer size="small" />
              <H6>Jumps over the lazy dog</H6>
              <Spacer size="medium" />
            </Block>
          </View>
        );

      case "copyright":
        return (
          <View vertical>
            <H2>Copyright</H2>
            <Spacer size="medium" />
            <Spacer size="small" />
            <Block>
              <Paragraph>
                You are free to use any of our brand assets for editorial
                purposes as long as you adere to our branding guidelines. This
                helps us make sure that our products and services are
                represented in a consistent and accurate way.<br></br>
                <br></br>Copyright infringement and/or misuse of our likeness
                and/our brand assets may results in legal action and ultimately
                may be punishable by law. If you have any questions, please
                contact us.
              </Paragraph>
            </Block>
          </View>
        );

      case "paragraphs":
        return (
          <View vertical>
            <H4>Paragraphs</H4>
            <Spacer size="medium" />
            <Spacer size="small" />
            <Block>
              <Subheading>Large paragraph</Subheading>
              <Spacer size="small" />
              <Paragraph size="big">
                The quick brown fox jumps over the lazy dog
              </Paragraph>
              <Spacer size="medium" />
              <Subheading>Paragraph</Subheading>
              <Spacer size="small" />
              <Paragraph>The quick brown fox jumps over the lazy dog</Paragraph>
              <Spacer size="medium" />
              <Subheading>Small paragraph</Subheading>
              <Spacer size="small" />
              <Paragraph size="small">
                The quick brown fox jumps over the lazy dog
              </Paragraph>
            </Block>
          </View>
        );

      case "links":
        return (
          <View vertical>
            <H4>Links</H4>
            <Spacer size="medium" />
            <Spacer size="small" />
            <Block>
              <Subheading>Large link</Subheading>
              <Spacer size="small" />
              <Link size="big">Click me</Link>
              <Spacer size="medium" />
              <Subheading>Link</Subheading>
              <Spacer size="small" />
              <Link>Click me</Link>
              <Spacer size="medium" />
              <Subheading>Small link</Subheading>
              <Spacer size="small" />
              <Link size="small">Click me</Link>
            </Block>
          </View>
        );

      case "buttons":
        return (
          <View vertical>
            <H4>Buttons</H4>
            <Spacer size="medium" />
            <Spacer size="small" />
            <Block>
              <Subheading>Button</Subheading>
              <Spacer size="small" />
              <View centerVertically gap="medium">
                <Button size="small">Click Me</Button>
                <Button>Click Me</Button>
                <Button size="big">Click Me</Button>
              </View>
              <Spacer size="medium" />
              <Subheading>Primary button</Subheading>
              <Spacer size="small" />
              <View centerVertically gap="medium">
                <PrimaryButton size="small">Click Me</PrimaryButton>
                <PrimaryButton>Click Me</PrimaryButton>
                <PrimaryButton size="big">Click Me</PrimaryButton>
              </View>
              <Spacer size="medium" />
              <Subheading>Secondary button</Subheading>
              <Spacer size="small" />
              <View centerVertically gap="medium">
                <SecondaryButton size="small">Click Me</SecondaryButton>
                <SecondaryButton>Click Me</SecondaryButton>
                <SecondaryButton size="big">Click Me</SecondaryButton>
              </View>
            </Block>
          </View>
        );

      case "inputs":
        return (
          <View vertical>
            <H4>Inputs</H4>
            <Spacer size="medium" />
            <Spacer size="small" />
            <Block>
              <Subheading>Text Input</Subheading>
              <Spacer size="small" />
              <View centerVertically gap="medium">
                <TextInput size="small" placeholder="Enter text here" />
                <TextInput placeholder="Enter text here" />
                <TextInput size="big" placeholder="Enter text here" />
              </View>
            </Block>
          </View>
        );
    }
  }

  function setTab(tab) {
    setSelectedTab(tab);
  }

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.menu}>
          <div className={styles.categoryContainer}>
            <H6 className={styles.tabTitle}>Introduction</H6>
            <Button
              onClick={() => setTab("brand story")}
              className={`${styles.tabButton} ${
                selectedTab == "brand story" ? styles.selectedTabButton : ""
              }`}
            >
              Brand story
            </Button>
            <Button
              onClick={() => setTab("our mission")}
              className={`${styles.tabButton} ${
                selectedTab == "our mission" ? styles.selectedTabButton : ""
              }`}
            >
              Our mission
            </Button>
            <Button
              onClick={() => setTab("messaging pillars")}
              className={`${styles.tabButton} ${
                selectedTab == "messaging pillars"
                  ? styles.selectedTabButton
                  : ""
              }`}
            >
              Messaging pillars
            </Button>
            <Button
              onClick={() => setTab("copyright")}
              className={`${styles.tabButton} ${
                selectedTab == "copyright" ? styles.selectedTabButton : ""
              }`}
            >
              Copyright
            </Button>
          </div>
          <div className={styles.categoryContainer}>
            <H6 className={styles.tabTitle}>Styleguide</H6>
            <Button
              onClick={() => setTab("headings")}
              className={`${styles.tabButton} ${
                selectedTab == "headings" ? styles.selectedTabButton : ""
              }`}
            >
              Headings
            </Button>
            <Button
              onClick={() => setTab("paragraphs")}
              className={`${styles.tabButton} ${
                selectedTab == "paragraphs" ? styles.selectedTabButton : ""
              }`}
            >
              Paragraphs
            </Button>
            <Button
              onClick={() => setTab("links")}
              className={`${styles.tabButton} ${
                selectedTab == "links" ? styles.selectedTabButton : ""
              }`}
            >
              Links
            </Button>
            <Button
              onClick={() => setTab("buttons")}
              className={`${styles.tabButton} ${
                selectedTab == "buttons" ? styles.selectedTabButton : ""
              }`}
            >
              Buttons
            </Button>
            <Button
              onClick={() => setTab("inputs")}
              className={`${styles.tabButton} ${
                selectedTab == "inputs" ? styles.selectedTabButton : ""
              }`}
            >
              Inputs
            </Button>
          </div>
        </div>
        <View className={styles.fixedWrapper}>
          <Content />
        </View>
      </div>
    </Container>
  );
}
